@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    #Home {
    background-image:url("https://nobuhiro-kato.com/image/background-image.jpg");
    background-size:cover;


    }

    @media screen and (min-width: 900px) {
       #Home {
        background-image:url("https://nobuhiro-kato.com/image/background-image-pc.jpg");
        background-size:cover;
       }
    }


    .button {
        @apply

        w-4/5
        border-2
        border-white

        md:w-1/2
        p-1
        my-4
        rounded-xl

        font-bold

        hover:bg-red-50;
        
    }

    
    .title {
        @apply

        pt-20 
        pb-10 
        text-3xl 
        font-bold
      text-center
    }


    .imageSize {
        @apply

        w-2/3
        md:w-1/3
        m-auto
        py-1
    }


}